import React, { useState, useContext, useEffect } from "react";

const LanguageContext = React.createContext();
const LanguageUpdateContext = React.createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

export function useLanguageUpdate() {
  return useContext(LanguageUpdateContext);
}

export function LanguageProvider({ children }) {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  useEffect(() => {
    localStorage.setItem("lang", currentLanguage);
  }, []);
  useEffect(() => {
    localStorage.setItem("lang", currentLanguage);
  }, [currentLanguage]);

  function toggleLanguage(language) {
    setCurrentLanguage(language);
  }

  return (
    <LanguageContext.Provider value={currentLanguage}>
      <LanguageUpdateContext.Provider value={toggleLanguage}>
        {children}
      </LanguageUpdateContext.Provider>
    </LanguageContext.Provider>
  );
}
